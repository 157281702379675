import CardMedium from 'components/cards/cardMedium';
import TitleDescription from 'components/ui/dashboard/titleDescription';

import styles from './productTools.module.css';

interface ProductToolsProps {}

export default function ProductTools(_props: ProductToolsProps) {
  return (
    <section className={styles.productTools}>
      <TitleDescription
        title="Product Content Tools"
        subtitle="Effortlessly boost your product listings with AI-powered tools and target market-defined user personas! Create product titles, descriptions, and metadata quickly and confidently."
        icon="tools"
        iconFrame={true}
        kind="dashboard-secondary"
      />
      <div className={styles.productToolsCards}>
        <CardMedium
          cardTitle="Single Product Title"
          cardSubtitle="Take full advantage of our advanced AI tool and create the perfect product titles!"
          cardLink="/generate/titles"
          cardLinkText=""
        />
        <CardMedium
          cardTitle="Single Product Description"
          cardSubtitle="Unleash the power of our description generator and make your products really stand out!"
          cardLink="/generate/descriptions"
          cardLinkText=""
        />
        <CardMedium
          cardTitle="Single Product Meta Description"
          cardSubtitle="Look no further if you need SEO optimised metadata for your product listing!"
          cardLink="#"
          cardLinkText=""
        />
      </div>
    </section>
  );
}
