import Icon from 'components/ui/icons';
import Locale from 'locale/lang';
import Link from 'next/link';

import styles from './sectionHeader.module.css';

interface SectionHeaderProps {
  title: string;
  subtitle: string;
  icon: string;
  iconFrame?: boolean | false;
  kind?:
    | 'dashboard-primary'
    | 'dashboard-secondary'
    | 'sidebar-primary'
    | 'sidebar-primary-no-borders'
    | 'sidebar-secondary';
}

export default function SectionHeader(props: SectionHeaderProps) {
  const lang = 'en';
  const { txt } = Locale({ lang });

  return (
    <section className={styles.sectionHeader}>
      <div className={styles.sectionHeaderTitle}>
        <Icon name={props.icon} framed={props.iconFrame} kind={props.kind} />
        <h2 className="h3">{props.title}</h2>
      </div>
      <div className={styles.sectionHeaderSubtitleBtn}>
        <div className={styles.sectionHeaderSubtitleWrapper}>
          <p className={styles.sectionHeaderSubtitle}>{props.subtitle}</p>
        </div>
        <Link href="/help">
          <button className="button button--wide">
            {txt.helpDocs.linkTitle}
          </button>
        </Link>
      </div>
    </section>
  );
}
