import Icon from 'components/ui/icons';

import styles from './titleDescription.module.css';

interface TitleDescriptionProps {
  title: string;
  subtitle: string;
  icon: string;
  iconFrame?: boolean | false;
  kind?:
    | 'dashboard-primary'
    | 'dashboard-secondary'
    | 'sidebar-primary'
    | 'sidebar-primary-no-borders'
    | 'sidebar-secondary';
}

export default function TitleDescription(props: TitleDescriptionProps) {
  return (
    <div className={styles.titleDescription}>
      <div className={styles.titleDescriptionHeading}>
        <Icon name={props.icon} framed={props.iconFrame} kind={props.kind} />
        <h3>{props.title}</h3>
      </div>
      <p>{props.subtitle}</p>
    </div>
  );
}
