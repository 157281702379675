import Avatar from 'components/ui/avatar';
import Icon from 'components/ui/icons';
import Link from 'next/link';

import styles from './tableProduct.module.css';

interface TableProductProps {
  data: {
    avatar_id: string;
    creation_date: string;
    description?: string;
    favorite?: boolean;
    generation_id: string;
    key_features?: any;
    metadata?: any;
    product_data?: any;
    title: string;
    tone_of_voice?: any;
    updated_date: string;
    user_id: string;
  };
}

export default function TableProduct(data: TableProductProps) {
  // console.log('TableProductProps:', data?.data);

  if (!data?.data) {
    return null;
  }

  const url = `/g/${data?.data.generation_id}`;
  return (
    <tr className={styles.tableProduct}>
      <td>
        <Avatar id={data?.data.avatar_id} />
        {/* {data?.data.avatarProfile} */}
      </td>
      <td>
        <Link href={url}>{data?.data.title}</Link>
      </td>
      <td>
        <Link href={url} className={styles.tableProductIcon}>
          <Icon name="chevron" />
        </Link>
      </td>
    </tr>
  );
}
