import Link from 'next/link';

import styles from './cardMedium.module.css';

interface CardMediumProps {
  cardTitle: string;
  cardSubtitle: string;
  cardLinkText: string;
  cardLink: string;
}

export default function CardMedium(props: CardMediumProps) {
  return (
    <div className={styles.cardMedium}>
      <h6>{props.cardTitle}</h6>
      <p>{props.cardSubtitle}</p>
      <Link href={props.cardLink}>
        {props.cardLinkText ? props.cardLinkText : 'Create New'}
      </Link>
    </div>
  );
}
