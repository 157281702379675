import TableProduct from 'components/ui/dashboard/tableProduct';
import TitleDescription from 'components/ui/dashboard/titleDescription';
import Link from 'next/link';
import { useUser } from 'utils/useUser';

import styles from './userData.module.css';

interface UserDataProps {}

export default function UserData(_props: UserDataProps) {
  const { generations, favourites } = useUser();

  // console.log('Generations:', generations);
  // console.log('Favourites:', generations);

  return (
    <div className={styles.userData}>
      {/* USER GENERATIONS */}
      <div className={styles.userDataUnit}>
        <TitleDescription
          title="My Products"
          subtitle="Generate product titles, description and meta data in seconds. "
          icon="products"
          iconFrame={true}
          kind="dashboard-secondary"
        />
        <Link className={styles.userDataLink} href="/g">
          View all
        </Link>
        <table className={styles.userDataTable}>
          <thead>
            <tr>
              <th>Avatar Profile</th>
              <th>Product Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {generations.map((gen: any, i: number) => (
              <TableProduct key={i} data={gen} />
            ))}
          </tbody>
        </table>
      </div>

      {/* USER FAVOURITES */}
      <div className={styles.userDataUnit}>
        <TitleDescription
          title="My Favorites"
          subtitle="Generate product titles, description and meta data in seconds. "
          icon="favorites"
          iconFrame={true}
          kind="dashboard-secondary"
        />
        <Link className={styles.userDataLink} href="/g/f">
          View all
        </Link>
        <table className={styles.userDataTable}>
          <thead>
            <tr>
              <th>Avatar Profile</th>
              <th>Product Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {favourites.map((fav: any, i: number) => (
              <TableProduct key={i} data={fav} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
