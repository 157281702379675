/* eslint-disable react/no-unescaped-entities */
import Locale from 'locale/lang';
import Link from 'next/link';
import { useState } from 'react';
import type { FAQItemProps } from 'types';

import styles from './faqs.module.css';

const FAQItem = ({ title, content }: FAQItemProps) => {
  const [isActive, setIsActive] = useState(false);

  let contact = false;

  if (content.toLowerCase().includes('contact')) {
    contact = true;
  }

  return (
    <div className={styles.faq}>
      <div
        className={styles.faqQuestion}
        onClick={() => setIsActive(!isActive)}
      >
        <div>{title}</div>
        <div className={styles.faqsControls}>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && (
        <div className={styles.faqAnswer}>
          {content}{' '}
          {contact && (
            <Link className="mt-2 block" href="/contact">
              Contact us
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default function FAQs() {
  const lang = 'en';
  const { txt } = Locale({ lang });

  return (
    <div id="faq" className={styles.faqs}>
      <h3 className={styles.faqsSubtitle}>{txt.faqs.title}</h3>
      <h4 className={styles.faqsTitle}>
        You've got questions. We've got answers.
      </h4>
      <div className={styles.faqsInner}>
        {txt.faqs?.items.map((item, i) => {
          return <FAQItem key={i} title={item.title} content={item.content} />;
        })}
      </div>
    </div>
  );
}
