import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { Auth, ThemeMinimal } from '@supabase/auth-ui-react';
import type { ViewType } from '@supabase/auth-ui-react/dist/esm/src/types';
import Dashboard from 'components/dashboard';
import FAQs from 'components/faqs';
import {
  BlogPosts,
  Features,
  HeroAlt,
  MailingList,
  PricingAlt,
} from 'components/marketing';
import LoadingDots from 'components/ui/LoadingDots';
import authStyles from 'components/ui/signInUpForm.module.css';
import type { GetStaticPropsResult } from 'next';
import { useEffect, useState } from 'react';
import type { Post, Product } from 'types';
import { getAllPosts } from 'utils/blog';
import { getURL } from 'utils/helpers';
import { getActiveProductsWithPrices } from 'utils/supabase-client';
import { useUser } from 'utils/useUser';

interface Props {
  products?: Product[];
  articles?: Post[];
  subscription?: any;
}

const LoggedOut = ({ products, articles }: Props) => {
  return (
    <>
      <HeroAlt />
      <MailingList />
      <Features />
      <PricingAlt products={products} />
      <BlogPosts articles={articles} />
      <FAQs />
    </>
  );
};

const LoggedInNoSubscription = ({ products, articles }: Props) => {
  return (
    <>
      <HeroAlt />
      <MailingList />
      <Features />
      <PricingAlt products={products} />
      <BlogPosts articles={articles} />
      <FAQs />
    </>
  );
};

const LoggedIn = ({ subscription, products, articles }: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading && subscription) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingDots version="spin" colour={true} />
      </div>
    );
  }

  if (subscription) {
    return <Dashboard />;
  }

  return <LoggedInNoSubscription products={products} articles={articles} />;
};

const ModalAuth = ({ client }: any) => {
  const [view, setView] = useState('' as ViewType | '');
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    // console.log('Session', session);

    const { data: authListener } = client.auth.onAuthStateChange(
      (event: any, currentSession: any) => {
        // console.log('🚨 Event', event);
        console.log('🚨 Current Session', currentSession);

        // console.log(authListener);

        switch (event) {
          case 'PASSWORD_RECOVERY':
            setView('update_password');
            setModalTitle('Update your password');
            setModalMessage(
              'Follow the instruction below to update your password.'
            );
            break;
          case 'USER_UPDATED':
            setView('');
            setModalTitle('Password updated');
            setModalMessage('Your password has been updated.');
            break;
          default:
            break;
        }
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  return view !== '' ? (
    <div className="pointer-events-auto fixed top-0 left-0 z-40 flex h-full w-full items-center justify-center backdrop-blur-xl">
      <div className="w-full max-w-2xl rounded-lg bg-white p-8 shadow-lg shadow-black/20">
        {modalTitle && <h2 className="text-2xl">{modalTitle}</h2>}
        {modalMessage && <p className="text-sm">{modalMessage}</p>}
        <div className={authStyles.form}>
          <Auth
            view={view}
            supabaseClient={client}
            providers={['google']}
            redirectTo={getURL()}
            magicLink={true}
            appearance={{
              theme: ThemeMinimal,
              variables: {
                default: {
                  colors: {
                    brand: '#933CFF',
                    brandAccent: '#52525b',
                  },
                },
              },
            }}
            theme="light"
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default function HomePage({ products, articles }: Props) {
  const supabaseClient = useSupabaseClient();
  const { user, subscription, session } = useUser();

  if (user) {
    return (
      <>
        <LoggedIn
          subscription={subscription}
          products={products}
          articles={articles}
        />
        <ModalAuth client={supabaseClient} session={session} />
      </>
    );
  }

  return (
    <>
      <LoggedOut products={products} articles={articles} />
      <ModalAuth client={supabaseClient} session={session} />
    </>
  );
}

export async function getStaticProps(): Promise<GetStaticPropsResult<Props>> {
  const products = await getActiveProductsWithPrices();
  const allPosts = await getAllPosts([
    'title',
    'date',
    'slug',
    'author',
    'coverImage',
    'coverBg',
    'excerpt',
  ]);

  return {
    props: {
      products,
      articles: allPosts,
    },
    revalidate: 60,
  };
}
