import {
  CoreActions,
  ProductTools,
  SectionHeader,
  UserData,
} from 'components/ui/dashboard';

export default function Dashboard() {
  return (
    <>
      <SectionHeader
        title="Dashboard"
        subtitle="Generate product titles, descriptions, and metadata in seconds with descripti.ai. Our AI technology can revolutionize the way you describe your products &ndash; try it today and see the difference!"
        icon="dashboard"
        kind="dashboard-primary"
        iconFrame={true}
      />
      <CoreActions />
      <ProductTools />
      <UserData />
    </>
  );
}
