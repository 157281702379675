import CardLarge from 'components/cards/cardLarge';
import TitleDescription from 'components/ui/dashboard/titleDescription';

import styles from './coreActions.module.css';

interface CoreActionsProps {}

export default function CoreActions(_props: CoreActionsProps) {
  return (
    <section className={styles.coreActions}>
      <div className={styles.coreActionsUnit}>
        <TitleDescription
          title="Create User Avatars"
          subtitle="Harness the power of your target audience by constructing user personas to gain an advantage and customise our AI-enabled toolkit when creating and refining your product titles, descriptions, and metadata."
          icon="persona"
          iconFrame={true}
          kind="dashboard-secondary"
        />
        <CardLarge
          cardTitle="Users Avatars 🦸‍♀"
          cardSubtitle="Create your ideal customer Avatar for targeted content"
          cardLinkText=""
          cardLink="#"
          showAvatars={true}
        />
      </div>
      <div className={styles.coreActionsUnit}>
        <TitleDescription
          title="Full Product Flow"
          subtitle="Generate product titles, description and meta data in seconds. Try
          descripti.ai today and see how our AI technology can transform the way
          you describe your products."
          icon="bolt"
          iconFrame={true}
          kind="dashboard-secondary"
        />
        <CardLarge
          cardTitle="Full Product Flow ⚡"
          cardSubtitle="Create your ideal customer Avatar for targeted content"
          cardLinkText=""
          cardLink="/generate/flow"
          showAvatars={false}
        />
      </div>
    </section>
  );
}
